import React from "react";
import { graphql } from "gatsby"; // to query for image data
import DiscographyIndexPage from "../components/DiscographyIndexPage";
import Img, { FluidObject } from "gatsby-image";
import get from "lodash/get";
import { AllDiscographySchema, DiscographyProps } from "../lib/";

const formatData = ({
  allDiscography,
  defaultImage,
}: AllDiscographySchema): DiscographyProps[] => {
  return allDiscography.nodes
    .map((node, i) => {
      const artworkFluid = get(node, "artwork.childImageSharp", defaultImage)
        .fluid;
      return {
        ...node,
        artwork: <Img fluid={artworkFluid} />,
      };
    })
    .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());
};

export const query = graphql`
  fragment DiscographyIndexAlbumArt on ImageSharp {
    fluid(maxWidth: 300, maxHeight: 300) {
      ...GatsbyImageSharpFluid
    }
  }

  query {
    allDiscography {
      nodes {
        title
        artwork {
          childImageSharp {
            ...DiscographyIndexAlbumArt
          }
        }
        date(formatString: "Y-MM-DD")
        slug
      }
    }

    defaultImage: imageSharp(
      original: { src: { regex: "/.*/album-default/" } }
    ) {
      ...DiscographyIndexAlbumArt
    }
  }
`;

export default ({ data, location }) => (
  <DiscographyIndexPage location={location} discography={formatData(data)} />
);
