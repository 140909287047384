import React from "react"
import { DiscographyIndexPageProps } from "@richsoni/gatsby-theme-core/src/lib"
import { Link } from "gatsby"
import Layout from "../../../components/headerFooterLayout"
import { Card, List } from "antd"
const { Meta } = Card

export default ({ location, discography }: DiscographyIndexPageProps) => (
  <Layout currentPath={location.pathname}>
    <List
      grid={{
        gutter: 8,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
      }}
      dataSource={discography}
      renderItem={({ slug, title, artwork, date }) => (
        <List.Item>
          <Link to={slug} style={{ display: "flex", flexFlow: "row nowrap" }}>
            <Card style={{ width: 300 }} bordered={false} cover={artwork}>
              <Meta title={title} description={date} />
            </Card>
          </Link>
        </List.Item>
      )}
    />
  </Layout>
)
